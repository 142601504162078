<template>
  <div class="page">
    <el-row :gutter="20">
      <el-col :span="18">
        <h2>商品列表</h2>
        <el-table :data="goodsList" border>
          <el-table-column label="货号/SKU" prop="skuid"></el-table-column>
          <el-table-column label="商品信息">
            <template slot-scope="scope">
              <div class="goodsInfo" >
                <el-image :src="scope.row.imgUrl || scope.row.gallery"/>
                <span>{{scope.row.name}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="goodsList.splice(scope.$index,1)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <h2>批量修改</h2>
          <el-form label-width="120px" label-position="left">
            <el-form-item label="销售价格">
              <el-input style="width: 210px" type="number" :min="0" v-model="goodsSetObj.price" placeholder="请输入产品销售价格"/>
            </el-form-item>
            <el-form-item label="库存">
              <el-input style="width: 210px" type="number" :min="0" v-model="goodsSetObj.stock" placeholder="请输入产品库存"/>
            </el-form-item>
            <el-form-item label="品牌">
              <el-select style="width: 210px" v-model="goodsSetObj.brand" placeholder="请选择品牌">
                <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="分类">
                <el-cascader
                        v-model="goodsSetObj.category"
                        :options="goodsCategoryList"
                        :props="{ expandTrigger: 'hover',checkStrictly: true,value:'id',label:'name',children:'children' }"
                        :show-all-levels="false"
                ></el-cascader>
<!--              <el-select style="width: 210px" v-model="goodsSetObj.category" placeholder="请选择分类">-->
<!--                <el-option v-for="item in goodsCategoryList" :key="item.id" :label="item.name" :value="item.id"></el-option>-->
<!--              </el-select>-->
            </el-form-item>
          </el-form>
        <h2>结果反馈</h2>
        <el-input type="textarea" :rows="4" v-model="result" readonly></el-input>
        <div style="margin-top: 10px;display: flex;align-items: center">
          <el-button type="primary" @click="setLot">执行</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {apiGetGoodsOptions,apiModfiyGoodsBaseOnce} from '@/request/api'

export default {
  name: "setLot",
  data(){
    return {
      goodsList:[],
      goodsSetObj:{
        price:'',
        stock:'',
        brand:'',
        category:''
      },
      result:'',
      brandList:[],
      goodsCategoryList:[]
    }
  },
  created() {
    this.goodsList = this.$route.params.goodsList;
    this.getOption();
  },
  methods:{
    setLot(){
      var list = []
      this.goodsList.forEach(item=>{
        var obj ={
          category_id: this.goodsSetObj.category,
          brand_id: this.goodsSetObj.brand,
          skuId: item.skuid,
          price: this.goodsSetObj.price,
          stock: this.goodsSetObj.stock
        }
        list.push(obj)
      })
      apiModfiyGoodsBaseOnce({sku:list}).then(res=>{
        if(res.code == 200){
          // this.resultList = res.data;
          res.data.forEach(item=>{
            this.result += item.skuId + ':' + item.msg + '\n';
          })

        }
      })
    },
    getOption(){
      apiGetGoodsOptions().then(res => {
        if(res.code == 200){
          this.brandList = res.data.brandCate;
          this.goodsCategoryList = res.data.goodsCate;
        }
      })
    }
  }
}
</script>

<style scoped>
.page {
  background-color: #FFF;
  padding: 20px;
  /*margin: 20px;*/
}
.el-form-item {
  margin-bottom: 10px;
}
</style>
